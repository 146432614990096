module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FinBB","short_name":"starter","start_url":"/","background_color":"#113786","theme_color":"#113786","display":"minimal-ui","icon":"src/images/finbb-favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"259d44241dd072ca82e6c4e54263420a"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/finbb-site/src/translations","languages":["en","fi"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5BSTJ2Q","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../finbb-dms-theme/gatsby-browser.js'),
      options: {"plugins":[],"name":"FINBB","languages":{"en":"en-US","fi":"fi-FI"},"templates":{"ContentfulArticlePage":"/opt/build/repo/finbb-site/src/templates/article-page.tsx","ContentfulPage":"/opt/build/repo/finbb-site/src/templates/page.tsx"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
